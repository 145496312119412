import React, { useState, useEffect } from "react";
import {
    Button,
    Picker,
    Input,
    AutoCenter,
    Form,
    Selector,
    Space
} from "antd-mobile";
import {
    AuthData,
    SpreadSheetData,
    PriceListParams,
    PriceListData
} from "@/types/api";
import { spreadSheetData, getPricelist } from "@/api/api";
import { DataTable } from "./components/ComponentDataTable";
import { List } from "antd-mobile";
import { useParams } from "react-router-dom";
import { Table } from "antd";
import { useLocation } from "react-router-dom";

const Home: React.FC = (props: any) => {
    const [options] = useState([
        { label: "Kyoto 206", value: 206 },
        { label: "Sakura 57", value: 57 }
    ]);

    const { currentShopId } = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    // const [selectedShopId, setSelectedShopId] = useState<number>(206);

    const location = useLocation();

    const queryParams = new URLSearchParams(window.location.search);
    const queryValue = queryParams.get("shopId");
    let selectedShopId = 206;
    if (queryValue !== null && queryValue !== undefined)
        selectedShopId = parseInt(queryValue);

    const [data, setData] = useState<SpreadSheetData>([]);
    const [priceListData, setPriceListData] = useState<PriceListData>([]);

    const [header, setHeader] = useState([
        {
            title: "Name",
            dataIndex: "name",
            key: "name"
        },
        {
            title: "30 m",
            dataIndex: "small",
            key: "small",
            render: (value: any) => <span>$ {value}</span>
        },
        {
            title: "45 m",
            dataIndex: "middle",
            key: "middle",
            render: (value: any) => <span>$ {value}</span>
        },
        {
            title: "60 m",
            dataIndex: "large",
            key: "large",
            render: (value: any) => <span>$ {value}</span>
        }
    ]);

    const loadPriceList = () => {
        const params: PriceListParams = {
            shopId: selectedShopId
        };
        getPricelist(params).then(res => {
            console.log(res);
            setPriceListData(res);
        });
    };

    useEffect(() => {
        loadPriceList();

        const intervalData = setInterval(() => {
            loadPriceList();
        }, 45000);

        return () => {
            clearInterval(intervalData);
        };
    }, []);

    return (
        <>
            {/* <h1 className={"title blink"}>Kyoto 206 Price List</h1> */}
            <Table
                dataSource={priceListData}
                columns={header}
                bordered={false}
                scroll={{ y: 1000 }}
                size={"large"}
                rowClassName={"customize-table"}
                pagination={false}
            />
            {/* <List header="Kyoto 206 Price List">
                {priceListData.map(item => {
                    return (
                        <>
                            <List.Item
                                style={{
                                    fontFamily:
                                        ""Dancing_Script variant0", Tofu"
                                }}
                            >
                                {item.name} - {item.small}
                            </List.Item>
                        </>
                    );
                })}
            </List> */}
            {/* <h3>Select Shop</h3> */}
        </>
    );
};

export default Home;
